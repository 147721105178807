import PropTypes from 'prop-types'
import { useTimer } from 'use-timer'
import { useDispatch } from 'react-redux'
import React, { useEffect, useLayoutEffect } from 'react'
import { Helmet } from 'react-helmet'
import { usePageVisibility } from 'react-page-visibility'
import { wrapFontFamily } from 'common/components/entities/Text/utils'
import { NetworkError } from 'common/errors'
import * as api from 'publisher/api/lectureSession'
import { useSessionLogEvent } from 'publisher/hooks/useSessionLogEvent'
import * as lectureActions from '../actions/lectureActions'
import useLecture, { selectors as lectureSelectors } from '../hooks/useLecture'
import usePage from '../hooks/usePage'
import { getGlobalFontFamily } from '../reducers/pageReducer'
import LectureUi from './ui/LectureUi'

const LECTURE_LOG_TIME = 15

function LecturePage({ children }) {
  const fontFamily = usePage(getGlobalFontFamily)
  const lectureId = useLecture(lectureSelectors.getLectureId)
  const lectureSessionId = useLecture(lectureSelectors.getLectureSessionId)
  const isLoggingEnabled = useLecture(
    lectureSelectors.isLectureSessionLoggingEnabled,
  )
  const isVisible = usePageVisibility()
  const dispatch = useDispatch()
  const staleTimer = useTimer()
  const updateTimer = useTimer({
    initialTime: LECTURE_LOG_TIME,
    endTime: 0,
    timerType: 'DECREMENTAL',
    onTimeOver: handleTimeOver,
  })

  useSessionLogEvent(() => dispatch(lectureActions.turnOffSessionLogging))

  async function handleTimeOver() {
    if (!isLoggingEnabled) return

    try {
      if (!lectureSessionId) {
        const { data } = await api.create(lectureId)
        dispatch(lectureActions.setLectureSessionId(data.lectureSessionId))
      } else {
        await api.update(lectureSessionId)
      }
    } catch (e) {
      if (e instanceof NetworkError) {
        // do nothing
      }
    }
    updateTimer.reset()
  }

  useEffect(() => {
    if (!isLoggingEnabled) {
      return
    }

    if (!updateTimer.isRunning) {
      updateTimer.start()
    }
  }, [updateTimer.time, updateTimer.isRunning])

  useEffect(() => {
    if (!isLoggingEnabled) {
      return
    }

    if (!isVisible) {
      staleTimer.start()
    }

    if (staleTimer.isRunning && isVisible) {
      if (staleTimer.time > LECTURE_LOG_TIME) {
        dispatch(lectureActions.unsetLectureSessionId)
        updateTimer.reset()
      }
      staleTimer.reset()
    }
  }, [isVisible])

  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      window.parent.postMessage({
        type: 'lecture_content_height',
        height: entries[0].target.clientHeight,
      })
    })

    // start observing a DOM node
    resizeObserver.observe(document.body)
  }, [])

  return (
    <React.Fragment>
      <Helmet />
      <LectureUi styles={{ fontFamily: wrapFontFamily(fontFamily) }}>
        {children}
      </LectureUi>
    </React.Fragment>
  )
}

LecturePage.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LecturePage
